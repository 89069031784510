import { DateTime } from 'luxon';
import { BaseAxisProps } from 'recharts/types/util/types';

// Formats numbers like 5M
export const numberFormatter = Intl.NumberFormat(undefined, {
    notation: 'compact',
});

// tick is a date string like 2021-11-30
export const formatTickOnXAxis: (
    totalDays: number
) => BaseAxisProps['tickFormatter'] =
    (totalDays: number) => (tick: string, index: number) => {
        const datetime = DateTime.fromISO(tick);

        // if 1-quarter, show Apr 1, Apr 15, May 1, May 15
        if (totalDays <= 92) {
            if (![1, 15].includes(datetime.day)) return '';
            return `${datetime.monthShort} ${datetime.day}`;
        }

        // if 1-year or less, show months with Jan having the year (Jan '22)
        if (totalDays <= 365) {
            if (datetime.day !== 1) return '';
            if (datetime.month !== 1) return datetime.monthShort;
            return `${datetime.monthShort} '${datetime.toFormat('yy')}`;
        }

        // show starts of quarters, up to 6 quarters
        if (totalDays <= 92 * 6) {
            if (datetime.day !== 1) return '';
            if (![1, 4, 7, 10].includes(datetime.month)) return '';
            if (datetime.month !== 1) return datetime.monthShort;
            return `${datetime.monthShort} '${datetime.toFormat('yy')}`;
        }

        // show starts of half-years, up to 8 halves)
        if (totalDays <= 365 * 4 + 1) {
            if (datetime.day !== 1) return '';
            if (![1, 7].includes(datetime.month)) return '';
            if (datetime.month !== 1) return datetime.monthShort;
            return `${datetime.monthShort} '${datetime.toFormat('yy')}`;
        }

        // if longer, show year markers
        if (datetime.day !== 1) return '';
        if (datetime.month !== 1) return '';
        return `${datetime.monthShort} '${datetime.toFormat('yy')}`;
    };

// tick is a number like 5K. We include the unit for the first y tick
export const formatTickOnYAxis: BaseAxisProps['tickFormatter'] = (
    tick: number
) => {
    return `${numberFormatter.format(tick)}`;
};
