import { useEffect, useMemo, useState } from 'react';
import { api, NoDataError, VehicleClass } from '../../data/api';
import { CitiesIndexInfo, DashboardData } from '../../data/city-info';

export interface DataBucket {
    xValue: string;
    yValue: number;
    percentageOfTotal: number;
}

interface SingleMetricData {
    dataBuckets: DataBucket[];
    average: number;
    median: number;
    xUnit: string;
    yUnit: string;
}

export interface SingleMetricDataWithPercentage {
    dataBuckets: DataBucket[];
    average: number;
    median: number;
    xUnit: string;
    yUnit: string;
}

export interface MetricsData {
    tripDistance: SingleMetricDataWithPercentage & {
        sumTotal: number;
    };
    tripDuration: SingleMetricDataWithPercentage;
    tripsByHour: SingleMetricDataWithPercentage;
    tripsPerDay: SingleMetricData;
    tripDistancePerDay: SingleMetricData;
    meanActiveVehiclesPerDay: SingleMetricData;
    tripsPerVehiclePerDay: SingleMetricData;
    totalTripCount: number;
    averageTripsPerDay: number;
}

export interface MetricsDataWithExternalTrips extends MetricsData {
    externalTripCount?: number;
}

export function useMetrics(
    slug: string,
    timePeriod: string,
    vehicleClass: VehicleClass
): MetricsData | null {
    const slugs = useMemo(() => [slug], [slug]);
    const metrics = useMetricsForMultipleCities(
        slugs,
        timePeriod,
        vehicleClass,
        null
    );
    return metrics?.[slug] ?? null;
}

/**
 * If a given city has no data, its metrics are null
 */
export type MetricsBySlug = Record<string, MetricsData | null>;

/**
 * Given a list of city slugs, returns a map of those slugs to their metrics
 *
 * Returns `null` while it is loading, and metrics for cities with no data are
 * returned as `null` as well.
 */
export function useMetricsForMultipleCities(
    slugs: string[],
    timePeriod: string,
    vehicleClass: VehicleClass,
    citiesIndexInfo: CitiesIndexInfo | null
): MetricsBySlug | null {
    const [data, setData] = useState<MetricsBySlug | null>(null);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        // The intent of this variable is to prevent race conflicts. If this effect
        // is called multiple times, we want our state to reflect the last data requested,
        // regardless of which async function returns last.
        // See https://github.com/facebook/react/issues/14326#issuecomment-441680293
        let didCancel = false;
        async function fetchMetrics() {
            setData(null);
            const [slugsToFetch, otherSlugs] = filterSlugsToFetch(
                slugs,
                vehicleClass,
                timePeriod,
                citiesIndexInfo?.cityIndexInfos ?? null
            );
            try {
                const responses = await Promise.all(
                    slugsToFetch.map(slug =>
                        api
                            .metrics(
                                slug,
                                vehicleClass as VehicleClass,
                                timePeriod
                            )
                            .then(response => [slug, response] as const)
                            .catch(err => {
                                if (err instanceof NoDataError)
                                    return [slug, null] as const;
                                else throw err;
                            })
                    )
                );
                // Add null result for trip metrics that don't exist with current filters
                otherSlugs.forEach(slug => {
                    responses.push([slug, null]);
                });
                if (!didCancel) {
                    setData(Object.fromEntries(responses));
                }
            } catch (err) {
                setError(err);
            }
        }

        fetchMetrics();
        return () => {
            // On cleanup, we cancel our request.
            didCancel = true;
        };
    }, [slugs, vehicleClass, timePeriod, citiesIndexInfo]);
    if (error) throw error;

    return data;
}

/**
 * If AllCitiesDataDict is available, use it to check if a trip_metrics.json file is available before requesting it
 * return a list of slugs to fetch and a list of slug to not fetch (metrics will all be zero)
 */
function filterSlugsToFetch(
    slugs: string[],
    vehicleClass: VehicleClass,
    timePeriod: string,
    dashboardDatas: DashboardData[] | null
): [string[], string[]] {
    if (!dashboardDatas) {
        return [slugs, []];
    }

    const slugsToFetch = slugs.filter(
        slug =>
            dashboardDatas.find(info => info.slug === slug)
                ?.vehicleTimePeriodSummaries[vehicleClass] &&
            dashboardDatas.find(info => info.slug === slug)
                ?.vehicleTimePeriodSummaries[vehicleClass][timePeriod]
    );
    const otherSlugs = slugs.filter(slug => !slugsToFetch.includes(slug));

    return [slugsToFetch, otherSlugs];
}
