import { map } from 'core-js/core/array';
import { DataBucket } from '../components/metrics/use-metrics';
import {
    ExternalTripCountsData,
    ExternalTripCountsDict,
    VehicleClass,
} from '../data/api';
import { getDateRangeFromQuarter } from './dates';
import { DateTime } from 'luxon';

export const externalTripCountsMetatdataSectionId = 'externalTripsMetadata';

export function getExternalCountForPeriod(
    timeRange: string | undefined,
    vehicleClass: VehicleClass | undefined,
    tripCounts: ExternalTripCountsDict
): number {
    if (!timeRange || !vehicleClass || !tripCounts) {
        return 0;
    }
    if (!tripCounts[vehicleClass]) {
        return 0;
    }
    if (!tripCounts[vehicleClass][timeRange]) {
        return 0;
    }
    return tripCounts[vehicleClass][timeRange];
}

function getDateRangeFromDataBuckets(
    dataBuckets: DataBucket[] | undefined
): [Date, Date] | null {
    if (dataBuckets === undefined) {
        return null;
    }
    const startDate = new Date(dataBuckets[0].xValue);
    const endDate = new Date(dataBuckets[dataBuckets.length - 1].xValue);
    return [startDate, endDate];
}

export function getEarliestDataDateIncludingExternalTripsFromString(
    earliestAvailable: string,
    externalTripCounts: ExternalTripCountsData
) {
    return getEarliestDataDateIncludingExternalTrips(
        DateTime.fromISO(earliestAvailable),
        externalTripCounts
    );
}

function getEarliestDataDateIncludingExternalTrips(
    earliestMDSDate: DateTime,
    externalTripCounts: ExternalTripCountsData
): DateTime {
    // Determine the earliest date data is available for across MDS and external trips
    const earliestExternalDate = externalTripCounts
        ? DateTime.fromISO(externalTripCounts.dataStartDate)
        : null;
    return earliestExternalDate &&
        earliestExternalDate?.toMillis() < earliestMDSDate.toMillis()
        ? earliestExternalDate
        : earliestMDSDate;
}

function getDataDateRangeFromTimeRangeString(
    timeRange: string,
    vehicleClass: VehicleClass,
    externalTripCounts: ExternalTripCountsData,
    dataBuckets: DataBucket[] | undefined
): [Date, Date] {
    // If the time range is all, need to get the total range for MDS and external trips
    if (timeRange === 'all') {
        const mdsDataRange = getDateRangeFromDataBuckets(dataBuckets);
        let externalDataRange: [Date, Date];
        // If all, then used defined data range
        if (vehicleClass == 'all') {
            externalDataRange = [
                new Date(externalTripCounts.dataStartDate),
                new Date(externalTripCounts.dataEndDate),
            ];
        } else {
            // Otherwise get check external trips for the vehicle class
            const quarters = Object.keys(
                externalTripCounts.tripCounts[vehicleClass]
            ).sort();
            externalDataRange = [
                getDateRangeFromQuarter(quarters[0])[0],
                getDateRangeFromQuarter(quarters[quarters.length - 2])[1],
            ]; // Using second to last to avoid the 'all' key
        }

        const combinedStartDate =
            mdsDataRange && mdsDataRange[0] < externalDataRange[0]
                ? mdsDataRange[0]
                : externalDataRange[0];
        const combinedEndDate =
            mdsDataRange && mdsDataRange[1] > externalDataRange[1]
                ? mdsDataRange[1]
                : externalDataRange[1];
        return [combinedStartDate, combinedEndDate];
    }
    // If the time range is a quarter, get the number of days for the quarter
    return getDateRangeFromQuarter(timeRange);
}

export function calculateAverageTripsPerDayWithExternalTrips(
    totalTrips: number,
    externalTrips: ExternalTripCountsData,
    timeRange: string,
    vehicleClass: VehicleClass,
    dataBuckets: DataBucket[]
) {
    const dateRange = getDataDateRangeFromTimeRangeString(
        timeRange,
        vehicleClass,
        externalTrips,
        dataBuckets
    );
    const numberOfDays =
        (dateRange[1].getTime() - dateRange[0].getTime()) / (1000 * 3600 * 24);
    const sumTotal =
        totalTrips +
        getExternalCountForPeriod(
            timeRange,
            vehicleClass,
            externalTrips.tripCounts
        );
    return Math.round(sumTotal / numberOfDays / 100) * 100;
}

function getExternalDataDateString(
    timeRange: string,
    vehicleClass: VehicleClass,
    externalTripCounts: ExternalTripCountsData
): string {
    if (timeRange == 'all') {
        if (vehicleClass == 'all') {
            return `from ${externalTripCounts.dataStartDate} to ${externalTripCounts.dataEndDate}`;
        }
        // Get quarters with external trips for this vehicle class
        const quarters = Object.keys(
            externalTripCounts.tripCounts[vehicleClass]
        ).sort();
        const start_date = getDateRangeFromQuarter(quarters[0])[0]
            .toISOString()
            .split('T')[0];
        const end_date = getDateRangeFromQuarter(
            quarters[quarters.length - 2]
        )[1]
            .toISOString()
            .split('T')[0]; // Using second to last to avoid the 'all' key

        return `from ${start_date} to ${end_date}`;
    }
    const dates = getDateRangeFromQuarter(timeRange);
    return `from ${dates[0].toISOString().split('T')[0]} to ${
        dates[1].toISOString().split('T')[0]
    }`;
}

function getDisplayedDateString(
    timeRange: string,
    vehicleClass: VehicleClass,
    dataBuckets: DataBucket[] | undefined,
    externalTripCounts: ExternalTripCountsData
): string {
    const dateRange = getDataDateRangeFromTimeRangeString(
        timeRange,
        vehicleClass,
        externalTripCounts,
        dataBuckets
    );
    return `${dateRange[0].toISOString().split('T')[0]} to ${
        dateRange[1].toISOString().split('T')[0]
    }`;
}

function getVehicleClassLabelWithSpecialCases(vc: string, mapviewName: string) {
    // Portland has this special case where the external trips bike trips are non-e-bikes but have but included with e-bikes to represent all BIKETOWN trips together
    return mapviewName == 'Portland, OR' && vc == 'e-bike' ? 'bike' : vc;
}

function formatCountForDisplay(count: number) {
    return (Math.round(count / 100) * 100).toLocaleString(undefined, {
        maximumFractionDigits: 0,
    });
}

function tripCountsStringByVehicleClass(
    tripCounts: ExternalTripCountsDict,
    timeRange: string,
    vehicleClass: VehicleClass,
    mapviewName: string
): string {
    // List all vehicle classes with external counts
    if (vehicleClass == 'all') {
        let tripCountsArr: string[] = [];
        Object.keys(tripCounts).forEach(key => {
            // Only include actual vehicle classes
            if (key !== 'all') {
                const count = getExternalCountForPeriod(
                    timeRange,
                    key as VehicleClass,
                    tripCounts
                );
                if (count > 0) {
                    tripCountsArr.push(
                        `${formatCountForDisplay(
                            count
                        )} ${getVehicleClassLabelWithSpecialCases(
                            key,
                            mapviewName
                        )} trips`
                    );
                }
            }
        });
        return tripCountsArr.join(' and ');
    }

    return `${formatCountForDisplay(
        getExternalCountForPeriod(timeRange, vehicleClass, tripCounts)
    )} ${getVehicleClassLabelWithSpecialCases(
        vehicleClass,
        mapviewName
    )} trips`;
}

export function formatTotalTripsMetadataMessage(
    value: number,
    timeRange: string,
    vehicleClass: VehicleClass,
    externalTripCounts: ExternalTripCountsData,
    mapviewName: string,
    dataBuckets?: DataBucket[],
    slug?: string
): string {
    const externalTripCount = getExternalCountForPeriod(
        timeRange,
        vehicleClass,
        externalTripCounts.tripCounts
    );
    let messageTemplate = externalTripCounts.total_trips_message;
    messageTemplate = messageTemplate.replace(
        '?<externalDateString>?',
        getExternalDataDateString(timeRange, vehicleClass, externalTripCounts)
    );
    messageTemplate = messageTemplate.replace('?<mapviewName>?', mapviewName);
    messageTemplate = messageTemplate.replace(
        '?<tripCountsByVc>?',
        tripCountsStringByVehicleClass(
            externalTripCounts.tripCounts,
            timeRange,
            vehicleClass,
            mapviewName
        )
    );
    const newCount = Math.round((value + externalTripCount) / 100) * 100;
    messageTemplate = messageTemplate.replace(
        '?<totalTrips>?',
        formatCountForDisplay(newCount)
    );
    messageTemplate = messageTemplate.replace(
        '?<displayedDateString>?',
        getDisplayedDateString(
            timeRange,
            vehicleClass,
            dataBuckets,
            externalTripCounts
        )
    );
    // If slug is passed, then we need to add it to the path to link to section on the mapview page
    const metaDataUrl = slug
        ? `/${slug}#${externalTripCountsMetatdataSectionId}`
        : `#${externalTripCountsMetatdataSectionId}`;
    const metaDataLink = `<a href="${metaDataUrl}" onclick="return true;">here</a>`;
    messageTemplate = messageTemplate.replace(
        '?<metadataSectionLink>?',
        metaDataLink
    );
    return messageTemplate;
}

export function formatAverageTripsPerDayMetadataMessage(
    totalTripCount: number,
    timeRange: string,
    vehicleClass: VehicleClass,
    externalTripCounts: ExternalTripCountsData,
    mapviewName: string,
    dataBuckets: DataBucket[],
    slug?: string,
    population?: number
): string | null {
    if (!externalTripCounts || !totalTripCount) {
        return null;
    }
    let messageTemplate = externalTripCounts.average_trips_per_day_message;
    messageTemplate = messageTemplate.replace(
        '?<externalDateString>?',
        getExternalDataDateString(timeRange, vehicleClass, externalTripCounts)
    );
    messageTemplate = messageTemplate.replace('?<mapviewName>?', mapviewName);
    let avgTrips = calculateAverageTripsPerDayWithExternalTrips(
        totalTripCount,
        externalTripCounts,
        timeRange,
        vehicleClass,
        dataBuckets
    );
    // If population is provided then we're calculating trips per day per 1k people
    avgTrips = population ? (avgTrips / population) * 1000 : avgTrips;
    const maximumFractionDigits = population ? 2 : 0;
    messageTemplate = messageTemplate.replace(
        '?<totalTripsPerDay>?',
        avgTrips.toLocaleString(undefined, {
            maximumFractionDigits: maximumFractionDigits,
        })
    );
    messageTemplate = messageTemplate.replace(
        '?<tripCountsByVc>?',
        tripCountsStringByVehicleClass(
            externalTripCounts.tripCounts,
            timeRange,
            vehicleClass,
            mapviewName
        )
    );

    messageTemplate = messageTemplate.replace(
        '?<displayedDateString>?',
        getDisplayedDateString(
            timeRange,
            vehicleClass,
            dataBuckets,
            externalTripCounts
        )
    );
    // If slug is passed, then we need to add it to the path to link to section on the mapview page
    const metaDataUrl = slug
        ? `/${slug}#${externalTripCountsMetatdataSectionId}`
        : `#${externalTripCountsMetatdataSectionId}`;
    const metaDataLink = `<a href="${metaDataUrl}" onclick="return true;">here</a>`;
    messageTemplate = messageTemplate.replace(
        '?<metadataSectionLink>?',
        metaDataLink
    );
    return messageTemplate;
}

// Type store explanation text in chunks, along with data needed for the link
interface ExternalTripsExplanationWithLink {
    linkText: string;
    linkUrl: string;
    postText: string;
    preText: string;
}

// Only one external link is supported at this time
export function formatExternalTripsExplanation(
    externalTripCounts: ExternalTripCountsData
): string | ExternalTripsExplanationWithLink {
    // If an external link is configured in the template, build a ExternalTripsExplanationWithLink and render it as text with a React link
    // If there's no link configured return the explanation string
    const text = externalTripCounts.external_trips_explanation;
    const linkIdx = text.indexOf('?<externalLink>?');
    if (linkIdx === -1) {
        return text;
    }
    const urlIdx = text.indexOf('?<linkUrl>?');
    const linkEndIdx = text.indexOf('?</externalLink>?');
    // If we don't have what we need, return and empty string; this is manually configured so we'll know we didn't get it right
    if (!urlIdx || !linkEndIdx || linkIdx > urlIdx || urlIdx > linkEndIdx) {
        return '';
    }
    const split1 = text.split('?<externalLink>?');
    const split2 = split1[1].split('?<linkUrl>?');
    const split3 = split2[1].split('?</externalLink>?');
    return {
        preText: split1[0],
        linkText: split2[0],
        linkUrl: split3[0],
        postText: split3[1],
    };
}
