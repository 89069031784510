const quartersLookup = {
    '1': {
        start_month: 0,
        start_day: 1,
        end_month: 2,
        end_day: 31,
    },
    '2': {
        start_month: 3,
        start_day: 1,
        end_month: 5,
        end_day: 30,
    },
    '3': {
        start_month: 6,
        start_day: 1,
        end_month: 8,
        end_day: 30,
    },
    '4': {
        start_month: 9,
        start_day: 1,
        end_month: 11,
        end_day: 31,
    },
};

export function getDateRangeFromQuarter(timeRange: string): [Date, Date] {
    const [year, quarter] = timeRange.split('-Q');

    return [
        new Date(
            parseInt(year),
            quartersLookup[quarter].start_month, // 0-based month index
            quartersLookup[quarter].start_day
        ),
        new Date(
            parseInt(year),
            quartersLookup[quarter].end_month,
            quartersLookup[quarter].end_day
        ),
    ];
}
