import cx from 'classnames';
import styles from '../styles/AboutThisData.module.scss';
import { ExternalTripCountsData } from '../data/api';
import {
    externalTripCountsMetatdataSectionId,
    formatExternalTripsExplanation,
} from '../utils/external-trip-counts';
import Link from 'next/link';

interface AboutThisDataProps {
    externalTripCounts?: ExternalTripCountsData | null;
}

function AboutThisData({ externalTripCounts }: AboutThisDataProps) {
    const externalTripsExplanation =
        externalTripCounts && externalTripCounts.external_trips_explanation
            ? formatExternalTripsExplanation(externalTripCounts)
            : null;
    return (
        <div className={cx('shadow-8', styles.container)}>
            <h2 className={styles.subheader}>About this data</h2>
            <p>
                Ride Report’s Global Micromobility Index is the first
                comprehensive data repository on how bikes and scooters
                accelerate transportation change. We’re grateful to work with
                agency staff and operators to bring this to life.
            </p>
            <p>
                We believe in the power of data to help define, measure, and
                achieve goals – but not at the expense of individual privacy.
                Ride Report safely ingests, stores, filters, and updates data on
                a quarterly basis to protect personally identifiable
                information.
            </p>
            <p>
                Ride Report does not buy or sell micromobility data. We have
                extensive data sharing agreements with our agency and operator
                partners. Learn more about our{' '}
                <a
                    className="link"
                    href="https://www.ridereport.com/solutions"
                    target="_blank"
                    rel="noreferrer"
                >
                    core product
                </a>
                , our{' '}
                <a
                    className="link"
                    href="https://www.ridereport.com/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                >
                    privacy policy
                </a>{' '}
                and our{' '}
                <a
                    className="link"
                    href="https://www.ridereport.com/blog/what-is-mds-questions"
                    target="_blank"
                    rel="noreferrer"
                >
                    role in shaping MDS
                </a>
                .
            </p>
            <p>
                If you have any ideas, questions, or feedback please be in
                touch:{' '}
                <a className="link" href="mailto:index@ridereport.com">
                    index@ridereport.com
                </a>
                .
            </p>
            {externalTripsExplanation && (
                <div
                    id={externalTripCountsMetatdataSectionId}
                    style={{ scrollMarginTop: '128px' }}
                >
                    <h2 className={styles.subheader}>Non-MDS Data</h2>
                    {typeof externalTripsExplanation === 'string' && (
                        <div>{externalTripsExplanation}</div>
                    )}
                    {typeof externalTripsExplanation === 'object' && (
                        <div>
                            {externalTripsExplanation.preText}
                            <a
                                href={externalTripsExplanation.linkUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {externalTripsExplanation.linkText}
                            </a>
                            {externalTripsExplanation.postText}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default AboutThisData;
