import { useCallback } from 'react';
import { useRouter } from 'next/router';

export function useQueryState<T extends string[]>(
    key: string,
    initialState: T[number]
): [T[number], (value: T[number]) => void, boolean] {
    const router = useRouter();

    const setValue = useCallback(
        (value: string) => {
            const newQuery = { ...router.query };
            if (value === initialState) {
                delete newQuery[key];
            } else {
                newQuery[key] = value;
            }
            router.replace({ query: newQuery }, undefined, {
                scroll: false,
            });
        },
        [initialState, key, router]
    );

    let value = router.query[key];
    if (typeof value != 'string') {
        value = initialState;
    }
    // Addressing this issue: Pages that are statically optimized by Automatic Static Optimization will be hydrated without their route parameters provided, i.e query will be an empty object ({}).
    // https://nextjs.org/docs/routing/dynamic-routes#caveats
    // router.query is empty on initial renders, and subsequently updates
    // Returning an isReady value allows components that care to wait until the actual query parameters are ready before using the results
    // See the setSelectedCities implementation in ComparisonChartTable for an example implementation
    const isReady = router.isReady;

    return [value, setValue, isReady];
}
